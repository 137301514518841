@import '../../../../assets/styles/globals';

.itemWrapper {
  border-bottom: 1px solid;
  border-color: $light-gray;
  padding: 0.75rem 0;

  &.selected {
    background-color: white;
    border-radius: 8px;
    border: none;
    padding: 0.5rem 0.75rem;
  }

  &.disabled {
    color: $placeholder-gray !important;
  }

  .title {
    font-weight: 700;
  }

  .subtitle {
    font-size: 0.9rem;
  }

  .xButton {
    background: transparent;
    border: none;

    svg {
      height: 1rem;
      width: 1rem;
      fill: $light-navy;
    }
  }
}

.selectLabel {
  font-weight: 700;
  margin-bottom: 0.25rem;
}
