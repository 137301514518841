@import '../../../assets/styles/globals.scss';

.communityFeed {
  width: 35%;
  border-right: 1px solid $placeholder-gray;
  height: 100%;

  @media (min-width: 1200px) {
    width: 28%;
  }

  @media (min-width: 1400px) {
    width: 500px;
  }

  .feedHeader {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    position: sticky;

    .iconWrapper {
      background: none;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }

    .icon {
      color: $secondary-color;

      &:hover {
        color: $secondary-color-alt;
      }
    }

    .feedTitle {
      color: $dark-navy;
      font-weight: 700;
    }

    .arrowWrapper {
      float: right;
      background-color: $secondary-color;
      height: 30px;
      width: 30px;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
        background-color: $secondary-color-alt;
      }

      .arrowButton {
        background-position: center;
        background-size: cover;
        height: 22px;
        width: 26px;
        flex-shrink: 0;
        border-style: none;
        background: url('../../../assets/images/common/icons/arrow-white.svg') no-repeat;
      }
    }
  }

  .feedHeaderCollapsed {
    justify-content: center;
  }

  .feedContainer {
    height: calc(100% - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 1rem 1rem;

    .loader {
      padding-top: 0 !important;
    }

    .placeholderText {
      color: $dark-navy;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;
      font-size: 15px;
    }

    .placeholderTitle {
      color: $dark-navy;
      font-weight: 700;
      padding-top: 20px;
    }
  }

  .loadingSection {
    gap: 2rem;
  }
}

.communityFeedCollapsed {
  width: 5% !important;
  border-right: none !important;
  background-color: white;
}

@media (max-width: 1199px) {
  .communityFeed {
    background-color: $off-white;
    z-index: 999;
    position: absolute;
  }

  .communityFeedCollapsed {
    background-color: white;
  }
}
