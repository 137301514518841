// Global variables go here ;D
$background-color: rgb(238, 238, 238);
$primary-color: #2b3843;
$secondary-color: #ff6633;
$secondary-color-alt: #ff6633;
$tertiary-color: #64747f;

// Orange
$primary-orange: $secondary-color;
$secondary-orange: $secondary-color-alt;

// Teal - Play Type
$lighter-teal: #d9ece8;
$light-teal: #86eed5;
$primary-teal: #33d9b2;
$dark-teal: #12a482;
$darker-teal: #157C5D;

// Blue - Train Type
$lighter-blue: #d7ebfa;
$light-blue: #79d1f7;
$primary-blue: #34ace0;
$dark-blue: #0e7cac;

// Purple - Social Type
$light-purple: #9eaaf6;
$primary-purple: #6677de;
$dark-purple: #243497;

// Border Grays
$border-lighter-gray: #d9d9d9;
$border-gray: #c4c4c4;
$border-darker-gray: #afb7bd;

$icon-gray: #afb7bd;
$slate-gray: #333F48;
$light-gray-text: #C1C5C8;
$border-slate: #42494F;

// Off-white in brand guide, so white elements can pop
$off-white: #f4f4f5;
// The gray created if light-navy at 50% opacity is placed on a white BG
// Good for placeholders, disabled/empty states, and thin dividing lines
$placeholder-gray: $border-darker-gray;

// Program Header
$header-dark-gray: #000e1acc;
$header-dark-blue: #05243ecc;
$header-dark-green: #0c2a23cc;

// Navy
$white-navy: #cad7e1;
$light-navy: #5f6f7c;
$primary-navy: #36434e;
$dark-navy: #1d2933;
$background-navy: #2E3941;

// Red
$red-darker: #d83127;
$red: #d4453d;
$red-delete: #d63939;

// Background colors
$light-gray-blue: #F6F7FA;
$dark-gray: #5B6670;

// Division 1-10 colors
$division-01-color: #ff6633; // orange
$division-02-color: #FFAB34; // yellow
$division-03-color: #12A482; // green
$division-04-color: #2BAAE1; // blue
$division-05-color: #B51E8C; // purple
$division-06-color: $division-01-color;
$division-07-color: $division-02-color;
$division-08-color: $division-03-color;
$division-09-color: $division-04-color;
$division-10-color: $division-05-color;

// Curriculum Rank 1-6 colors
$curriculum-rank-01-color: $division-01-color; // orange rank
$curriculum-rank-02-color: $division-02-color; // yellow rank
$curriculum-rank-03-color: $division-03-color; // green rank
$curriculum-rank-04-color: $division-04-color; // blue rank
$curriculum-rank-05-color: $division-05-color; // purple rank
$curriculum-rank-06-color: $dark-navy; // black rank

//Warning
$light-red: #ed213a;
$dark-red: #93291e;

// FONT COLORS
$white-font-color: #ffffff;
$dark-font-color: rgb(67, 80, 91);
$mid-gray: #888;
$light-gray: #ddd;
$error-font-color: #ff001f;
$mid-font-color: rgba(67, 80, 91, 0.9);
$light-font-color: $light-navy;

// GENERAL COLORS
$transparent: rgba(0, 0, 0, 0);
$dark-transparent-overlay: rgba(29, 41, 51, 0.75);
$disable-color: #e6e6e6;

// SIGN IN FLOW
$user-signin-background-color: #43505b;

// INPUT COLORS
$input-background-color: #ffffff;
$input-error-color: #cc0000;

// ACTIVITY FEED
$activity-feed-backround: #f4f4f5;
$show-buttons-color: #f8f8f8;
$action-button-color: #c2c6cb;

// COMMENTS BACKGROUND
$comments-backround: #f8f8f8;
$comments-form-border: #e7e7e7;

// RANK
$locked-rank-background-color: #dddfe1;

// OBJECTIVE
$objective-tabbar-background-color: #ffffff;
$objective-details-background-color: #ffffff;
$objective-link-background-color: #e2e7eb;

// STAT COLORS
$stat-color-1: #8acd00;
$stat-color-2: #5fa000;
$stat-color-3: #2d6800;
$stat-color-4: #f2a800;
$stat-color-5: #f24539;
$stat-color-6: #4e8ee9;
$stat-color-7: #b7fe00;

// OBJECTIVE RANK COLORS
$objective-rank-1: $curriculum-rank-01-color;
$objective-rank-2: $curriculum-rank-02-color;
$objective-rank-3: $curriculum-rank-03-color;
$objective-rank-4: $curriculum-rank-04-color;
$objective-rank-5: $curriculum-rank-05-color;
$objective-rank-6: $curriculum-rank-06-color;
$objective-rank-7: $curriculum-rank-06-color;
$objective-rank-8: $curriculum-rank-06-color;
$objective-rank-9: $curriculum-rank-06-color;
$objective-rank-10: $curriculum-rank-06-color;

// BORDERS
$borderBase: solid 1px lighten($mid-font-color, 60%);

//Links
$link-color: $primary-color !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

// Other height/width values
$sidebar-width: 200px;
$expandable-sidebar-width-collapsed: 90px;
$expandable-sidebar-width-expanded: 280px;
$page-header-height: 70px;
$page-footer-height: 60px;
$pagination-height: 60px;
$modal-header-height: 45px;

// Padding values (can also be used for margin)
$primary-padding: 36px;
$secondary-padding: 18px;
$field-margin: 16px;

// Border radius values
$card-corner-radius: 8px;

// Drop shadow values
$non-pressable-elem-drop-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
$pressable-elem-drop-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
$topnav-drop-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
$modal-drop-shadow: 0px 10px 20px rgba(0, 0, 0, 0.75);

// Font weights
$bold-font-weight: 700; // Looks extra chunky on Chrome - dunno why

// Font-sizes
$heading-0-size: 48px; // For hero text
$heading-1-size: 36px; // 48px is way too big for normal use; 36px is better
$heading-2-size: 24px;
$heading-3-size: 18px;
$heading-4-size: 16px;

// Ideal width and height for Academy Display resolution
// Used in SASS calculations to make more responsive sizing
$ideal-academy-display-width: 1920;
$ideal-academy-display-height: 1080;

$event-banner-height: 45px;

:export {
  red: $red;
  lightRed: $light-red;
  lightNavy: $light-navy;
  primaryNavy: $primary-navy;
  darkNavy: $dark-navy;
  lightGray: $light-gray;
  darkGray: $dark-gray;
  slateGray: $slate-gray;
  placeholderGray: $placeholder-gray;
  secondaryColor: $secondary-color;
  secondaryColorAlt: $secondary-color-alt;
  whiteColor: $white-font-color;
  primaryTeal: $primary-teal;
  lighterTeal: $lighter-teal;
  darkTeal: $dark-teal;
  redDarker: $red-darker;
  lighterBlue: $lighter-blue;
  primaryBlue: $primary-blue;
  darkBlue: $dark-blue;
  primaryPurple: $primary-purple;
  offWhite: $off-white;
  primaryOrange: $primary-orange;
  secondaryOrange: $secondary-orange;
  disableColor: $disable-color;
  divisionOne: $division-01-color;
  divisionTwo: $division-02-color;
  divisionThree: $division-03-color;
  divisionFour: $division-04-color;
  divisionFive: $division-05-color;
  divisionSix: $division-06-color;
  headerDarkGray: $header-dark-gray;
  headerDarkBlue: $header-dark-blue;
  headerDarkGreen: $header-dark-green;
}

// Common
// @import 'common/colors';
@import 'common/mixins';
// @import "common/typography";
// @import "common/helper";
// @import "common/buttons";
@import 'common/transitions';
@import 'common/alert';
// @import "common/modals";

// PULL TO REFRESH STYLES
@import 'components/common/PullToRefresh';